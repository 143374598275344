html {
  height: 100%;
}

body {
  margin: 0;
  line-height: 1.5;
}

h1, small {
  display: inline-block;
}

h2, p, h3, dl, ol, ul {
  margin: 0;
}

a {
  color: inherit;
}

img {
  max-width: 100%;
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

.u-upper-case {
  letter-spacing: .05em;
  text-transform: uppercase;
}

.u-visually-hidden {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.c-button, .c-button:after, .c-button.-is-small, .c-button.-is-small:after {
  border-radius: 3px;
}

.c-button {
  color: #fff;
  cursor: pointer;
  background: #d01127;
  border: none;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  transition: box-shadow .1s, transform .1s;
  display: inline-block;
  position: relative;
  box-shadow: 0 4px 4px -2px #00000040;
}

.c-button, .c-button.-is-small {
  padding: 6px 10px;
  font-size: 11pt;
}

.c-button:after {
  content: "";
  width: 100%;
  height: 100%;
  transition: filter .5s, opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.c-button:after, .c-button.-is-sunny-on-top:after {
  background-image: radial-gradient(farthest-side at 100% 0, #f9ee7133, #0000);
}

.c-button.-is-large, .c-button.-is-large:after {
  border-radius: 5px;
}

.c-button.-is-large {
  padding: 8px 15px;
  font-size: 15pt;
}

.c-button.-is-sunny-on-bottom:after {
  background-image: radial-gradient(farthest-side at 0 100%, #f9ee7133, #0000);
}

.c-button:hover:after, .c-button:focus:after {
  opacity: 0;
}

@supports (filter: opacity(0)) {
  .c-button:hover:after, .c-button:focus:after {
    opacity: initial;
    filter: opacity(0);
  }
}

@supports (filter: opacity()) {
  .c-button:hover:after, .c-button:focus:after {
    opacity: initial;
    filter: opacity(0);
  }
}

.c-button:active {
  box-shadow: none;
  transform: translateY(2px);
}

@media only screen and (width >= 800px) {
  .c-button.-is-large {
    font-size: 18pt;
  }
}

.c-brand-list {
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.c-brand-list__item {
  flex: 33.333%;
  max-width: 150px;
}

.c-brand-list__logo {
  width: 100%;
  display: block;
}

@media only screen and (width >= 1024px) {
  .c-brand-list {
    gap: 50px;
  }
}

.c-icon-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.c-icon-list__item {
  display: inline-block;
}

.c-icon-list__item + .c-icon-list__item {
  margin-left: 20px;
}

@supports (flex-wrap: wrap) {
  .c-icon-list {
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
  }

  .c-icon-list__item {
    margin-left: 0 !important;
  }
}

.c-input, .c-input.-is-small {
  border-radius: 3px;
  font-size: 11pt;
}

.c-input {
  border: none;
  padding: 6px 10px;
}

.c-input.-is-multi-line {
  height: 10em;
}

.c-nav {
  font-size: 11pt;
}

.c-nav.-is-list .c-nav__items {
  list-style-type: disc;
}

.c-nav.-is-horizontal .c-nav__items, .c-nav.-is-responsive .c-nav__items, .c-nav.-is-vertical .c-nav__items {
  padding: 0;
  list-style-type: none;
}

.c-nav__link {
  text-decoration: none;
}

.c-nav.-is-horizontal .c-nav__link, .c-nav.-is-responsive .c-nav__link, .c-nav.-is-vertical .c-nav__link {
  line-height: 47px;
  display: inline-block;
}

.c-nav.-is-horizontal .c-nav__link {
  text-align: center;
}

.c-nav__link:after {
  content: attr(data-text);
  content: attr(data-text) / "";
  visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  height: 0;
  font-weight: bold;
  display: block;
  overflow: hidden;
}

@media speech {
  .c-nav__link:after {
    display: none;
  }
}

.c-nav__link:hover, .c-nav__link.-is-active {
  font-weight: bold;
}

.c-nav__link.-is-active {
  cursor: default;
}

.c-nav__item:has(.c-button), .c-nav.-is-responsive .c-nav__item:has(.c-button), .c-nav.-is-vertical .c-nav__item:has(.c-button) {
  padding: 12.5px 0;
}

.c-nav.-is-responsive .c-nav__item:last-child, .c-nav.-is-vertical .c-nav__item:last-child {
  padding-bottom: 0;
}

@media only screen and (width >= 800px) {
  .c-nav.-is-responsive .c-nav__items {
    align-items: baseline;
    gap: 25px;
    display: flex;
  }

  .c-nav.-is-responsive .c-nav__item:has(.c-button) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .c-nav.-is-responsive .c-nav__link {
    line-height: normal;
    display: block;
  }
}

@media only screen and (width >= 1024px) {
  .c-nav.-is-horizontal .c-nav__items, .c-nav.-is-responsive .c-nav__items {
    gap: 50px;
  }

  .c-nav.-is-responsive .c-nav__link {
    text-align: center;
  }
}

.c-side-bar__logo {
  margin-top: -.2em;
  text-decoration: none;
  display: inline-block;
}

.c-side-bar__nav {
  margin-top: 36px;
}

.c-timer, .c-timer__digits {
  display: flex;
}

.c-timer {
  justify-content: space-between;
  gap: 10px;
}

.c-timer__digit-group {
  flex: 1;
}

.c-timer__digit-group-label {
  text-align: center;
  margin-top: 5px;
}

.c-timer__digit {
  aspect-ratio: 2 / 2.5;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 1ch;
  padding-top: .05em;
  font-size: 20pt;
  display: flex;
}

.c-timer__digit + .c-timer__digit {
  margin-left: 5px;
}

.c-timer__digit-group-label {
  text-align: center;
  width: 100%;
  font-size: 10.5pt;
  text-decoration: none;
  display: inline-block;
}

@media only screen and (width >= 480px) {
  .c-timer.-is-responsive {
    gap: 25px;
  }

  .c-timer.-is-responsive .c-timer__digit {
    font-size: 24pt;
  }

  .c-timer.-is-responsive .c-timer__digit-group-label {
    font-weight: 500;
  }

  .c-timer.-is-responsive .c-timer__digit-group-label[title] {
    visibility: hidden;
  }

  .c-timer.-is-responsive .c-timer__digit-group-label[title]:before {
    content: attr(title);
    visibility: visible;
    width: 100%;
    margin-bottom: -2em;
    display: inline-block;
  }

  @media speech {
    .c-timer.-is-responsive .c-timer__digit-group-label[title]:before {
      display: none;
    }
  }
}

@media only screen and (width >= 1024px) {
  .c-timer.-is-responsive .c-timer {
    gap: 15px;
  }
}

@media only screen and (width >= 1280px) {
  .c-timer.-is-responsive .c-timer {
    gap: 25px;
  }
}

body {
  overflow-x: hidden;
}

h2, .c-hero__summary {
  font-size: 12pt;
}

h3, .c-header__logo, .c-side-bar__logo {
  font-size: 15pt;
}

h3 {
  line-height: 1.2;
}

h2 + h3 {
  margin-top: 50px;
}

h2 + p, h3 + p, h2 + dl, h3 + dl, h2 + ul, h3 + ul, h2 + ol, h3 + ol, .c-hero__summary .c-footer__socials, .c-footer__copyright, .c-contact-form, .c-contact-form__row + .c-contact-form__row, .c-contact-form__input-set + .c-contact-form__input-set, .c-contact-form__submit-button, .c-footer__disclaimer, .c-footer__socials {
  margin-top: 25px;
}

p + p {
  margin-top: 1em;
}

ul {
  padding-left: 1.1em;
}

.c-header {
  z-index: 2;
  width: 100%;
  position: fixed;
}

.c-header__inner, .c-hero__inner, .c-footer__inner {
  box-sizing: border-box;
  width: 100%;
  padding: 0 25px;
}

.c-header__inner {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.c-header__nav-button {
  -webkit-user-select: none;
  user-select: none;
  padding: 14px 7px;
}

.c-header__nav-icon {
  color: #0000;
  width: 16px;
  height: 2px;
  transition: background-color .25s;
  display: inline-block;
  position: relative;
}

.c-header__nav-icon:before, .c-header__nav-icon:after {
  content: "";
  width: 16px;
  height: 2px;
  display: block;
  position: absolute;
}

.c-header__nav-icon:before {
  margin-top: -5px;
}

.c-header__nav-icon:after {
  top: 5px;
}

.c-header__nav-button:has( + .c-header__nav-icon-checkbox:checked) .c-header__nav-icon {
  background-color: #0000;
}

.c-header__nav-icon:before, .c-header__nav-icon:after {
  transition: transform .5s;
}

.c-header__nav-button:has( + .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:before, .c-header__nav-button:has( + .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:after {
  transform-origin: center;
}

.c-header__nav-button:has( + .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:before {
  transform: translateY(250%)rotate(45deg);
}

.c-header__nav-button:has( + .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:after {
  transform: translateY(-250%)rotate(-45deg);
}

.c-header__nav-icon-checkbox, .c-side-bar, .c-how-to-buy__side-bar, .c-call-to-action__side-bar {
  display: none;
}

.c-header__nav {
  max-height: 0;
  margin-top: 11px;
  margin-bottom: 2.5px;
  overflow: hidden;
}

.c-header__nav-icon-checkbox:checked + .c-header__nav {
  overflow: initial;
  max-height: 100%;
}

@supports (flex-wrap: wrap) {
  .c-header__inner {
    flex-wrap: wrap;
    padding: 22.5px 25px;
  }

  .c-header__logo {
    flex: 75%;
  }
}

@supports (display: grid) {
  .c-header__inner {
    grid-template-rows: 75px 0fr;
    grid-template-columns: 1fr auto;
    padding: 0 25px;
    transition: grid-template-rows .5s;
    display: grid;
  }

  .c-header__inner:has(.c-header__nav-icon-checkbox:checked) {
    grid-template-rows: 75px 1fr;
  }

  .c-header__logo {
    flex: 75%;
  }

  .c-header__nav {
    grid-column: 1 / span 2;
    max-height: 100%;
    margin: 0;
    padding: 0;
    transition: scaleY .5s;
    transform: scaleY(0);
  }

  .c-header__nav-icon-checkbox:checked + .c-header__nav {
    overflow: hidden;
    transform: scaleY(1);
  }

  .c-header__nav-items {
    margin-bottom: 25px;
  }
}

.c-hero, .c-footer {
  padding-bottom: 25px;
}

.c-hero {
  position: relative;
}

.c-hero__inner, .c-call-to-action__call, .c-call-to-action__action-set {
  text-align: center;
}

.c-hero__title {
  display: none;
}

.c-hero__character-background {
  box-sizing: border-box;
  width: 100vw;
  margin-left: -25px;
  padding: 100px 25px 0;
  position: relative;
}

.c-hero__character-background:before {
  content: "";
  width: 100%;
  height: calc(100px + 55%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.c-character, .c-character__media {
  position: relative;
}

.c-character__media {
  display: inline-block;
  transform: rotate(15deg);
}

.c-hero__character, .c-hero__summary {
  margin-top: 75px;
}

.c-hero__character {
  max-width: 500px;
  margin: 0 auto;
  animation: 3s cubic-bezier(.16, 1, .3, 1) fly-in, 10s ease-in-out 3s infinite hover;
}

.c-hero__summary {
  max-width: 50ch;
  display: inline-block;
}

@supports (display: grid) {
  .c-hero__character-background {
    grid-template-rows: 100px 9fr 3fr 1fr;
    grid-template-columns: 25px 1fr 25px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    display: grid;
  }

  .c-hero__character-background:before {
    content: "";
    grid-area: 1 / 1 / span 2 / span 3;
    height: 100%;
    display: block;
  }

  .c-hero__character {
    grid-area: 2 / 2 / span 2;
    align-self: end;
  }

  @supports (filter: drop-shadow(-10px 0 0 white)) {
    .c-hero__summary {
      margin-top: 35px;
    }
  }
}

.c-footer {
  padding-top: 25px;
}

.c-contact-form {
  text-align: right;
  margin-bottom: 0;
}

.c-contact-form__input-set + .c-contact-form__input-set {
  display: block;
}

.c-contact-form__input {
  width: 100%;
}

.c-footer__aside {
  margin-top: 50px;
}

.c-footer__sitemap {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media only screen and (width >= 360px) {
  .c-contact-form__row:first-child {
    gap: 25px;
    display: flex;
  }

  .c-contact-form__input-set {
    flex: 1;
  }

  .c-contact-form__input-set + .c-contact-form__input-set {
    margin-top: 0;
  }
}

@media only screen and (width >= 480px) {
  @supports (display: grid) {
    .c-hero__character-background {
      grid-template-rows: 25vw 9fr 3fr 1fr;
    }
  }
}

@media only screen and (width >= 640px) {
  @supports (display: grid) {
    .c-hero__title {
      filter: drop-shadow(0 0 10px #000);
      z-index: 1;
      max-width: 400px;
      margin-top: 100px;
      margin-bottom: 0;
      display: inline-block;
      position: absolute;
      left: calc(50% - 200px);
    }

    .c-hero__character-background {
      grid-template-rows: 225px 9fr 3fr 1fr;
    }
  }

  .c-footer__inner {
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    display: grid;
  }

  .c-contact-form__row:first-child {
    display: initial;
  }

  .c-contact-form__input-set + .c-contact-form__input-set {
    margin-top: 25px;
  }

  .c-footer__aside, .c-footer__socials, .c-footer__copyright {
    margin-top: 0;
  }

  .c-footer__sitemap {
    padding-top: .3em;
  }

  .c-footer__socials, .c-footer__copyright {
    grid-column: 1 / span 2;
  }
}

@media only screen and (width >= 800px) {
  .c-header {
    height: 100px;
  }

  .c-header__inner {
    padding-right: 0;
    display: flex;
  }

  .c-header__logo {
    flex: none;
    margin-top: -.2em;
  }

  .c-header__nav-button, .c-header__buy-button {
    display: none;
  }

  .c-header__nav {
    overflow: initial;
    padding-bottom: 0;
    transform: scaleY(1);
  }

  .c-header__nav-items {
    margin-bottom: 0;
  }

  .c-hero, .c-footer {
    padding-bottom: 50px;
  }

  .c-hero__title {
    display: inline-block;
  }

  .c-hero__summary, .c-contact-form, .c-footer__disclaimer {
    margin-top: 50px;
  }

  @supports (display: grid) {
    .c-hero__title {
      margin-top: 125px;
    }

    .c-hero__character-background {
      grid-template-rows: 275px 9fr 3fr 1fr;
    }

    .c-hero__summary {
      margin-top: 70px;
    }
  }

  .c-footer {
    padding-top: 50px;
  }

  .c-footer__inner {
    gap: 50px;
  }

  .c-footer__contact {
    grid-row: 1 / span 3;
  }

  .c-contact-form__input-set + .c-contact-form__input-set, .c-footer__socials, .c-footer__copyright {
    margin-top: 0;
  }

  .c-contact-form__row:first-child {
    display: flex;
  }

  .c-footer__socials, .c-footer__copyright {
    grid-column: 2;
    justify-self: right;
  }
}

@media only screen and (width >= 1024px) {
  .c-header__inner {
    padding-right: 25px;
  }

  .c-header__buy-button {
    display: inline-block;
  }

  .c-hero {
    padding-top: 150px;
    padding-bottom: 200px;
  }

  .c-hero__inner {
    text-align: initial;
    grid-template-rows: 1fr repeat(2, auto);
    grid-template-columns: 4fr .769fr .979fr 5.253fr 1fr;
    row-gap: 50px;
    display: grid;
  }

  .c-hero__title, .c-hero__summary {
    margin: 0;
  }

  .c-hero__title {
    position: initial;
    filter: none;
    grid-area: 1 / 1 / auto / span 2;
    align-self: end;
    padding-right: 50px;
  }

  .c-hero__character-background {
    background: none;
    grid-area: 1 / 2 / span 3 / span 3;
    align-self: center;
    width: auto;
    margin: 0;
    padding: 0;
    display: block;
  }

  .c-hero__character {
    max-width: none;
  }

  .c-hero__summary {
    grid-column: 1;
  }

  .c-footer__inner {
    grid-template-columns: 2fr 3fr;
  }

  .c-footer__aside {
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    gap: 50px;
    display: grid;
  }

  .c-footer__disclaimer {
    grid-area: 2 / 2;
    margin-top: .2em;
  }

  .c-footer__sitemap {
    position: initial;
    width: initial;
    height: initial;
    clip: initial;
    grid-column: 1;
    margin: 0;
  }
}

@media only screen and (width >= 1280px) {
  .c-header__inner, .c-hero__inner, .c-footer__inner {
    width: 1180px;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
  }

  .c-hero {
    padding-bottom: 400px;
  }
}

@media only screen and (width >= 1920px) {
  @supports ((-webkit-mask: linear-gradient(to bottom, black, black)) or (mask: linear-gradient(to bottom, black, black))) {
    .c-header {
      height: 7px;
    }

    .c-header__inner {
      display: none;
    }

    .c-side-bar {
      z-index: 1;
      display: block;
      position: fixed;
      top: 50px;
      left: 50px;
    }
  }
}

body, button, .c-input {
  font-family: Montagu Slab, serif;
}

body {
  background: #006646;
  font-family: Montagu Slab, serif;
  font-size: 11pt;
}

h2, .c-header__logo, .c-side-bar__logo {
  letter-spacing: .05em;
  text-transform: uppercase;
  font-family: Rye, serif;
  font-weight: normal;
}

.c-header, .c-side-bar, .c-hero, .c-about, .c-tokenomics, .c-roadmap, .c-footer {
  color: #fff;
}

.c-header, .c-header.-is-transparent {
  background-image: linear-gradient(to right, #d01127, #d01127), linear-gradient(to right, #d01127 50%, #0000 50%), linear-gradient(to right, #0000 50%, #d01127 50%), linear-gradient(#006646, #0000);
  background-position-y: top, 4px, 5px, top;
  background-repeat: repeat-x;
  background-size: 10px 4px, 10px 1px, 10px 1px, 100% 100%;
}

.c-header:before, .c-header:after {
  content: "";
  background-repeat: repeat-x;
  width: 100%;
  transition: filter .5s, opacity .5s;
  position: absolute;
}

.js .c-header.-is-opaque:before, .js .c-header.-is-opaque:after, .c-header:has(.c-header__nav-icon-checkbox:checked):before, .c-header:has(.c-header__nav-icon-checkbox:checked):after {
  opacity: initial;
}

@supports (filter: opacity(1)) {
  .js .c-header.-is-opaque:before, .js .c-header.-is-opaque:after, .c-header:has(.c-header__nav-icon-checkbox:checked):before, .c-header:has(.c-header__nav-icon-checkbox:checked):after {
    opacity: initial;
    filter: opacity();
  }
}

.js .c-header:before, .js .c-header:after {
  opacity: 0;
}

@supports (filter: opacity(0)) {
  .js .c-header:before, .js .c-header:after {
    opacity: initial;
    filter: opacity(0);
  }
}

.c-header:before {
  z-index: -1;
  background-image: linear-gradient(to right, #d01127, #d01127), linear-gradient(to right, #d01127 50%, #0000 50%), linear-gradient(to right, #0000 50%, #d01127 50%), linear-gradient(#006646, #006646), linear-gradient(to right, #006646 50%, #0000 50%);
  background-position-y: top, 4px, 5px, top, bottom;
  background-size: 10px 4px, 10px 1px, 10px 1px, 100% calc(100% - 1px), 10px 1px;
  height: 100%;
}

.c-header:after {
  background-image: linear-gradient(to right, #0000 50%, #006646 50%);
  background-size: 10px 1px;
  height: 1px;
}

.c-header__logo {
  text-decoration: none;
}

.c-header__nav-icon, .c-header__nav-icon:before, .c-header__nav-icon:after {
  background: #fff;
}

@supports (display: grid) {
  .c-hero__character-background:before {
    background-image: linear-gradient(#0000 50%, #006646), url("assets/img/clouds-480x360.7fd09a6c.webp"), url("assets/img/landscape-480x360.4be8dc81.webp");
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@supports (filter: drop-shadow(0 10px 0 white)) {
  .c-character__media:before {
    content: "";
    filter: drop-shadow(0 5px #fff);
    z-index: -2;
    background: url("assets/svg/pepito-silhouette.75fcd316.svg") 0 0 / contain no-repeat;
    width: 100%;
    height: 100%;
    padding-bottom: 5px;
    position: absolute;
    left: 0;
    -webkit-mask-image: linear-gradient(-10deg, #fff 34%, #0000 35%);
    mask-image: linear-gradient(-10deg, #fff 34%, #0000 35%);
  }
}

.c-character__speech {
  content: "Flying high on life";
  color: #000;
  text-align: left;
  box-sizing: border-box;
  background-image: url("assets/svg/speech-bubble.e866b465.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 93.75px;
  height: 52.5px;
  margin: 0;
  padding: 8px 9px;
  font-family: Mansalva, sans-serif;
  font-size: 12pt;
  line-height: 1.1;
  position: absolute;
  top: -20%;
  left: 60%;
}

.c-character__speech:first-letter {
  letter-spacing: .05em;
}

.c-hero__summary, .c-call-to-action__call, .c-footer__copyright {
  text-wrap: balance;
}

.c-hero__greeting {
  font-weight: bold;
}

.c-about, .c-how-to-buy, .c-tokenomics, .c-roadmap, .c-call-to-action, .c-footer {
  background-repeat: repeat-x;
}

.c-about, .c-how-to-buy, .c-tokenomics, .c-roadmap, .c-call-to-action {
  background-size: 10px 1px;
}

.c-about, .c-roadmap {
  background-image: linear-gradient(to right, #0000 50%, #fff 50%);
  background-position-y: bottom;
}

.c-how-to-buy, .c-call-to-action {
  background-color: #fff;
  background-position-y: top, bottom;
}

.c-how-to-buy__side-bar, .c-call-to-action__side-bar {
  color: #000;
}

.c-how-to-buy {
  background-image: linear-gradient(to right, #0000 50%, #006646 50%), linear-gradient(to right, #0000 50%, #d01127 50%);
}

.c-tokenomics, .c-roadmap {
  background-color: #b80f23;
}

.c-tokenomics {
  background-image: linear-gradient(to right, #0000 50%, #fff 50%);
  background-position-y: top;
}

.c-tokenomics__distribution-chart:before, .c-tokenomics__distribution-chart:after {
  content: "";
  position: absolute;
}

.c-tokenomics__distribution-chart:before {
  z-index: -1;
  background: #b80f23;
  width: 100vw;
  height: 100%;
  margin-top: -26px;
  padding-top: 26px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 25px 15px #b80f23;
}

.c-tokenomics__distribution-chart:after {
  pointer-events: none;
  background-image: url("assets/img/coin-240x240.6ab40e0a.webp");
  background-repeat: no-repeat;
  background-size: contain;
  width: 90%;
  max-width: 480px;
  height: 90%;
  max-height: 480px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-call-to-action {
  background-image: linear-gradient(to right, #0000 50%, #d01127 50%), linear-gradient(to right, #0000 50%, #006646 50%);
}

.c-footer {
  background-image: linear-gradient(to right, #0000 50%, #fff 50%), linear-gradient(to right, #0000 50%, #d01127 50%), linear-gradient(to right, #d01127 50%, #0000 50%), linear-gradient(to right, #d01127, #d01127);
  background-position-y: top, calc(100% - 5px), calc(100% - 4px), bottom;
  background-repeat: repeat-x;
  background-size: 10px 1px, 10px 1px, 10px 1px, 10px 4px;
}

@media only screen and (min-device-pixel-ratio: 1.5) {
  .c-hero__character-background {
    background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-480x360@2x.798b6dda.webp"), url("assets/img/2x/landscape-480x360@2x.1077e4de.webp");
  }

  @supports (display: grid) {
    .c-hero__character-background {
      background-image: none;
    }

    .c-hero__character-background:before {
      background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-480x360@2x.c158e3ae.png"), url("assets/img/2x/landscape-480x360@2x.4a9942f5.jpg");
    }
  }

  .c-tokenomics__distribution-chart:after {
    background-image: url("assets/img/coin-240x240.6ab40e0a.webp");
  }
}

@media only screen and (width >= 360px) {
  .c-hero__character-background {
    background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-640x480@2x.02a17e54.webp"), url("assets/img/2x/landscape-640x480@2x.3ac19b81.webp");
  }

  @supports (display: grid) {
    .c-hero__character-background {
      background-image: none;
    }

    .c-hero__character-background:before {
      background-image: linear-gradient(#0000 50%, #006646), url("assets/img/clouds-640x480.2c19a112.png"), url("assets/img/landscape-640x480.6f3169dd.jpg");
    }
  }

  .c-character__speech {
    top: -10%;
  }

  .c-tokenomics__distribution-chart:after {
    background-image: url("assets/img/coin-360x360.6043617b.webp");
  }
}

@media only screen and (width >= 360px) and (min-device-pixel-ratio: 1.5) {
  .c-hero__character-background {
    background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-640x480@2x.02a17e54.webp"), url("assets/img/2x/landscape-640x480@2x.3ac19b81.webp");
  }

  @supports (display: grid) {
    .c-hero__character-background {
      background-image: none;
    }

    .c-hero__character-background:before {
      background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-640x480@2x.94fd6de7.png"), url("assets/img/2x/landscape-640x480@2x.343f8a98.jpg");
    }
  }

  .c-tokenomics__distribution-chart:after {
    background-image: url("assets/img/2x/coin-360x360@2x.9893c381.webp");
  }
}

@media only screen and (width >= 480px) {
  body {
    font-size: 12pt;
  }

  h3 {
    font-size: 20pt;
  }

  .c-hero__character-background {
    background-image: linear-gradient(#0000 50%, #006646), url("assets/img/clouds-960x720.798b6dda.webp"), url("assets/img/landscape-960x720.1077e4de.webp");
  }

  @supports (display: grid) {
    .c-hero__character-background {
      background-image: none;
    }

    .c-hero__character-background:before {
      background-image: linear-gradient(#0000 50%, #006646), url("assets/img/clouds-960x720.c158e3ae.png"), url("assets/img/landscape-960x720.d1e5dbf1.jpg");
    }
  }

  .c-character__speech, .c-hero__summary {
    font-size: 15pt;
  }

  .c-character__speech {
    width: 125px;
    height: 70px;
    padding: 12px 15px;
  }

  .c-tokenomics__distribution-chart:after {
    background-image: url("assets/img/coin-480x480.4b3e8a72.webp");
  }
}

@media only screen and (width >= 480px) and (min-device-pixel-ratio: 1.5) {
  .c-hero__character-background {
    background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-960x720@2x.d28dc2aa.webp"), url("assets/img/2x/landscape-960x720@2x.3396eb72.webp");
  }

  @supports (display: grid) {
    .c-hero__character-background {
      background-image: none;
    }

    .c-hero__character-background:before {
      background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-960x720@2x.61083a83.png"), url("assets/img/2x/landscape-960x720@2x.c2058378.jpg");
    }
  }

  .c-tokenomics__distribution-chart:after {
    background-image: url("assets/img/2x/coin-480x480@2x.30e9d939.webp");
  }
}

@media only screen and (width >= 800px) {
  .c-tokenomics__distribution-chart:before {
    display: none;
  }

  @supports (display: grid) {
    .c-hero__character-background {
      background-image: none;
    }

    .c-hero__character-background:before {
      background-image: linear-gradient(#0000 50%, #006646), url("assets/img/clouds-1024x768.817e43b8.png"), url("assets/img/landscape-1024x768.53f6fd4a.jpg");
    }
  }

  @supports (filter: drop-shadow(-10px 0 0 white)) {
    .c-character__media:before {
      filter: drop-shadow(0 10px #fff);
      padding-bottom: 10px;
      -webkit-mask-image: linear-gradient(-9deg, #fff 32%, #0000 34%);
      mask-image: linear-gradient(-9deg, #fff 32%, #0000 34%);
    }
  }
}

@media only screen and (width >= 800px) and (min-device-pixel-ratio: 1.5) {
  @supports (display: grid) {
    .c-hero__character-background {
      background-image: none;
    }

    .c-hero__character-background:before {
      background-image: linear-gradient(#0000 50%, #006646), url("assets/img/2x/clouds-1024x768@2x.c54d4625.png"), url("assets/img/2x/landscape-1024x768@2x.91bd46b4.jpg");
    }
  }
}

@media only screen and (width >= 1024px) {
  h3 {
    font-size: 24pt;
  }

  .c-hero:before {
    content: "";
    z-index: -1;
    background-image: radial-gradient(farthest-side at 100% 0, #0000 50%, #006646), url("assets/img/clouds-720x1080.27709e90.webp"), url("assets/img/landscape-720x1080.27942257.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 45%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .c-hero__character-background, .c-hero__character-background:before {
    background: none;
  }

  @supports (filter: drop-shadow(-10px 0 0 white)) {
    .c-character__media:before {
      filter: drop-shadow(-10px 0 #fff);
      background-position-x: 10px;
      padding-bottom: 0;
      padding-left: 10px;
      animation: 3s cubic-bezier(.16, 1, .3, 1) add-shade;
      left: -10px;
      -webkit-mask-image: linear-gradient(75deg, #fff 49%, #0000 43%);
      mask-image: linear-gradient(75deg, #fff 49%, #0000 43%);
    }
  }

  .c-character__speech {
    top: -5%;
  }
}

@media only screen and (width >= 1024px) and (min-device-pixel-ratio: 1.5) {
  .c-hero:before {
    background-image: radial-gradient(farthest-side at 100% 0, #0000 50%, #006646), url("assets/img/2x/clouds-720x1080@2x.f8f598ec.webp"), url("assets/img/2x/landscape-720x1080@2x.47e1d52f.webp");
  }
}

@media only screen and (width >= 1440px) {
  .c-hero:before {
    background-image: radial-gradient(farthest-side at 100% 0, #0000 50%, #006646), url("assets/img/clouds-960x1080.d058c00a.webp"), url("assets/img/landscape-960x1080.4cdee6cd.webp");
  }
}

@media only screen and (width >= 1440px) and (min-device-pixel-ratio: 1.5) {
  .c-hero:before {
    background-image: radial-gradient(farthest-side at 100% 0, #0000 50%, #006646), url("assets/img/2x/clouds-960x1080@2x.ecf9ba03.webp"), url("assets/img/2x/landscape-960x1080@2x.83ab074d.webp");
  }
}

@media only screen and (width >= 1920px) {
  @supports ((-webkit-mask: linear-gradient(to bottom, black, black)) or (mask: linear-gradient(to bottom, black, black))) {
    .c-header:before, .c-header:after {
      display: none;
    }

    .c-header {
      background-image: linear-gradient(to right, #d01127, #d01127), linear-gradient(to right, #d01127 50%, #0000 50%), linear-gradient(to right, #0000 50%, #d01127 50%);
    }
  }
}

@media only screen and (width >= 1921px) {
  .c-hero:before {
    background-image: radial-gradient(farthest-side at 100% 0, #0000 50%, #006646), url("assets/img/clouds-1440x1080.c5ad10ae.webp"), url("assets/img/landscape-1440x1080.9d34447c.webp");
  }
}

@media only screen and (width >= 1921px) and (min-device-pixel-ratio: 1.5) {
  .c-hero:before {
    background-image: radial-gradient(farthest-side at 100% 0, #0000 50%, #006646), url("assets/img/2x/clouds-1440x1080@2x.bc901e4a.webp"), url("assets/img/2x/landscape-1440x1080@2x.1a76c7fa.webp");
  }
}

@keyframes hover {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5%);
  }

  100% {
    transform: translateY(0);
  }
}

#teaser {
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  display: flex;
}

@supports (display: grid) {
  #teaser {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    display: grid;
  }
}

#teaser .c-hero__summary {
  margin-top: 35px;
}

#teaser .c-hero__hook {
  margin-top: 25px;
  font-size: 15pt;
  font-weight: bold;
}

#teaser .c-footer {
  z-index: 1;
  background-image: linear-gradient(to right, #0000 50%, #d01127 50%), linear-gradient(to right, #d01127 50%, #0000 50%), linear-gradient(to right, #d01127, #d01127);
  background-position-y: calc(100% - 5px), calc(100% - 4px), bottom;
  background-size: 10px 1px, 10px 1px, 10px 4px;
  position: relative;
}

@media only screen and (width >= 480px) {
  #teaser .c-header__inner {
    padding-right: 25px;
  }

  #teaser .c-header__logo {
    flex: none;
    margin-top: -.2em;
  }

  #teaser .c-hero__hook {
    font-size: 20pt;
  }

  #teaser .c-footer__inner {
    grid-template-rows: repeat(2, auto);
  }
}

@media only screen and (width >= 1024px) {
  #teaser .c-hero {
    padding-bottom: 200px;
  }

  #teaser .c-hero__hook, #teaser .c-hero__summary {
    margin-top: 0;
  }

  #teaser .c-hero__hook {
    grid-area: 3 / 1 / auto / span 3;
    font-size: 24pt;
  }

  #teaser .c-footer {
    margin-top: -150px;
  }
}
/*# sourceMappingURL=index.83691b31.css.map */

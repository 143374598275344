#teaser {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;

    @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }

    .c-hero__summary { margin-top: 35px; }

    .c-hero__hook {
        font-size: 15pt;
        font-weight: bold;
        margin-top: 25px;
    }

    /* .c-hero__countdown { margin-top: 35px; }

    .c-hero__countdown-title {
        font-family: "Montagu Slab", serif;
        font-size: 15pt;
        font-weight: bold;
        text-transform: none;
        letter-spacing: normal;
    } */

    /* .c-hero__countdown-timer, .c-hero__summary { margin-top: 25px; }

    .c-hero__countdown-digits { font-family: Rye, serif; } */

    .c-footer {
        position: relative;

        background-image:
            linear-gradient(to right, transparent 50%, hsl(353 85% 44%) 50%),
            linear-gradient(to right, hsl(353 85% 44%) 50%, transparent 50%),
            linear-gradient(to right, hsl(353 85% 44%), hsl(353 85% 44%));

        background-position-y: calc(100% - 5px), calc(100% - 4px), bottom;
        background-size: 10px 1px, 10px 1px, 10px 4px;
        z-index: 1;
    }

    @media only screen and (min-width: 480px) {
        .c-header__inner { padding-right: 25px; }

        .c-header__logo {
            flex: none;
            margin-top: -.2em;
        }

        .c-hero__hook { font-size: 20pt; }

        /* .c-hero__countdown-title { font-size: 20pt; }

        .c-hero__countdown-timer {
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
        } */

        .c-footer__inner { grid-template-rows: repeat(2, auto); }
    }

    @media only screen and (min-width: 1024px) {
        .c-hero { padding-bottom: 200px; }

        .c-hero__hook, .c-hero__summary { margin-top: 0; }

        .c-hero__hook {
            grid-column: 1 / span 3;
            grid-row: 3;
            font-size: 24pt;
        }

        /* .c-hero__countdown, .c-hero__summary { margin-top: 0; }

        .c-hero__countdown {
            grid-column: 1 / span 2;
            grid-row: 3;
        }

        .c-hero__countdown-title { font-size: 24pt; } */

        .c-footer { margin-top: -150px; }
    }
}

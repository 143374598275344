html { height: 100%; }

body {
    line-height: 1.5;
    margin: 0;
}

h1, small { display: inline-block; }

h2, p { margin: 0; }

h3 { margin: 0; }

dl, ol, ul { margin: 0; }

a { color: inherit; }

img {
    display: block;
    max-width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
    html { scroll-behavior: smooth; }
}

.c-timer, .c-timer__digits { display: flex; }

.c-timer {
    justify-content: space-between;
    gap: 10px;
}

.c-timer__digit-group { flex: 1; }

.c-timer__digit-group-label {
    text-align: center;
    margin-top: 5px;
}

.c-timer__digit {
    display: flex;
    width: 1ch;
    aspect-ratio: 2 / 2.5;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: 20pt;
    color: black;
    padding-top: 0.05em;
    border-radius: 3px;
}

.c-timer__digit + .c-timer__digit { margin-left: 5px; }

.c-timer__digit-group-label {
    display: inline-block;
    width: 100%;
    font-size: 10.5pt;
    text-decoration: none;
    text-align: center;
}

@media only screen and (min-width: 480px) {
    .c-timer.-is-responsive { gap: 25px; }

    .c-timer.-is-responsive .c-timer__digit { font-size: 24pt; }

    .c-timer.-is-responsive .c-timer__digit-group-label { font-weight: 500; }

    .c-timer.-is-responsive .c-timer__digit-group-label[title] { visibility: hidden; }

    .c-timer.-is-responsive .c-timer__digit-group-label[title]:before {
        display: inline-block;
        width: 100%;
        content: attr(title);
        margin-bottom: -2em;
        visibility: visible;
    }

    @media speech {
        .c-timer.-is-responsive .c-timer__digit-group-label[title]:before { display: none; }
    }
}

@media only screen and (min-width: 1024px) {
    .c-timer.-is-responsive .c-timer { gap: 15px; }
}

@media only screen and (min-width: 1280px) {
    .c-timer.-is-responsive .c-timer { gap: 25px; }
}

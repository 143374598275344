.c-nav { font-size: 11pt; }

.c-nav.-is-list .c-nav__items { list-style-type: disc; }

.c-nav.-is-horizontal .c-nav__items, .c-nav.-is-responsive .c-nav__items, .c-nav.-is-vertical .c-nav__items {
    list-style-type: none;
    padding: 0;
}

.c-nav__link { text-decoration: none; }

.c-nav.-is-horizontal .c-nav__link, .c-nav.-is-responsive .c-nav__link, .c-nav.-is-vertical .c-nav__link {
    display: inline-block;
    line-height: 47px;
}

.c-nav.-is-horizontal .c-nav__link { text-align: center; }

.c-nav__link::after {
    display: block;
    height: 0;
    content: attr(data-text);
    content: attr(data-text) / "";
    font-weight: bold;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
}

@media speech {
    .c-nav__link::after { display: none; }
}

.c-nav__link:hover, .c-nav__link.-is-active { font-weight: bold; }

.c-nav__link.-is-active { cursor: default; }

.c-nav__item:has(.c-button),
.c-nav.-is-responsive .c-nav__item:has(.c-button),
.c-nav.-is-vertical .c-nav__item:has(.c-button) {
    padding: 12.5px 0;
}

.c-nav.-is-responsive .c-nav__item:last-child, .c-nav.-is-vertical .c-nav__item:last-child {
    padding-bottom: 0;
}

@media only screen and (min-width: 800px) {
    .c-nav.-is-responsive .c-nav__items {
        display: flex;
        align-items: baseline;
        gap: 25px;
    }

    .c-nav.-is-responsive .c-nav__item:has(.c-button) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .c-nav.-is-responsive .c-nav__link {
        display: block;
        line-height: normal;
    }
}

@media only screen and (min-width: 1024px) {
    .c-nav.-is-horizontal .c-nav__items, .c-nav.-is-responsive .c-nav__items { gap: 50px; }

    .c-nav.-is-responsive .c-nav__link { text-align: center; }
}

body, button, .c-input { font-family: "Montagu Slab", serif; }

body { background: hsl(161 100% 20%); }

body {
    font-family: "Montagu Slab", serif;
    font-size: 11pt;
}

h2, .c-header__logo, .c-side-bar__logo {
    font-family: Rye, serif;
    font-weight: normal;
    letter-spacing: .05em;
    text-transform: uppercase;
}

.c-header, .c-side-bar, .c-hero, .c-about, .c-tokenomics, .c-roadmap, .c-footer { color: white; }

.c-header, .c-header.-is-transparent {
    background-image:
        linear-gradient(to right, hsl(353 85% 44%), hsl(353 85% 44%)),
        linear-gradient(to right, hsl(353 85% 44%) 50%, transparent 50%),
        linear-gradient(to right, transparent 50%, hsl(353 85% 44%) 50%),
        linear-gradient(to bottom, hsl(161 100% 20%), transparent);

    background-position-y: top, 4px, 5px, top;
    background-size: 10px 4px, 10px 1px, 10px 1px, 100% 100%;
    background-repeat: repeat-x;
}

.c-header:before, .c-header:after {
    position: absolute;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    transition: filter .5s, opacity .5s;
}

.c-header:has(.c-header__nav-icon-checkbox:checked):before,
.c-header:has(.c-header__nav-icon-checkbox:checked):after,
.js .c-header.-is-opaque:before,
.js .c-header.-is-opaque:after {
    @mixin opacity 1;
}

.js .c-header:before, .js .c-header:after { @mixin opacity 0; }

.c-header:before {
    height: 100%;
    background-image:
        linear-gradient(to right, hsl(353 85% 44%), hsl(353 85% 44%)),
        linear-gradient(to right, hsl(353 85% 44%) 50%, transparent 50%),
        linear-gradient(to right, transparent 50%, hsl(353 85% 44%) 50%),
        linear-gradient(to bottom, hsl(161 100% 20%), hsl(161 100% 20%)),
        linear-gradient(to right, hsl(161 100% 20%) 50%, transparent 50%);

    background-position-y: top, 4px, 5px, top, bottom;
    background-size: 10px 4px, 10px 1px, 10px 1px, 100% calc(100% - 1px), 10px 1px;
    z-index: -1;
}

.c-header:after {
    height: 1px;
    background-image: linear-gradient(to right, transparent 50%, hsl(161 100% 20%) 50%);
    background-size: 10px 1px;
}

.c-header__logo { text-decoration: none; }

.c-header__nav-icon, .c-header__nav-icon:before, .c-header__nav-icon:after { background: white; }

@supports (display: grid) {
    .c-hero__character-background:before {
        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url("../assets/img/clouds-480x360.png"),
            url("../assets/img/landscape-480x360.jpg");

        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url("../assets/img/clouds-480x360.webp"),
            url("../assets/img/landscape-480x360.webp");

        background-position-y: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@supports (filter: drop-shadow(0 10px 0 white)) {
    .c-character__media:before {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../assets/svg/pepito-silhouette.svg);
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
        padding-bottom: 5px;
        filter: drop-shadow(0 5px 0 white);
        mask-image: linear-gradient(-10deg, #fff 34%, #0000 35%);
        z-index: -2;
    }
}

.c-character__speech {
    position: absolute;
    left: 60%;
    top: -20%;
    width: 93.75px;
    height: 52.5px;
    background-image: url(../assets/svg/speech-bubble.svg);
    background-size: contain;
    background-repeat: no-repeat;
    content: "Flying high on life";
    font-family: Mansalva, sans-serif;
    font-size: 12pt;
    color: black;
    text-align: left;
    line-height: 1.1;
    padding: 8px 9px;
    box-sizing: border-box;
    margin: 0;
}

.c-character__speech::first-letter { letter-spacing: 0.05em; }

.c-hero__summary, .c-call-to-action__call, .c-footer__copyright { text-wrap: balance; }

.c-hero__greeting { font-weight: bold }

.c-about, .c-how-to-buy, .c-tokenomics, .c-roadmap, .c-call-to-action, .c-footer { background-repeat: repeat-x; }

.c-about, .c-how-to-buy, .c-tokenomics, .c-roadmap, .c-call-to-action { background-size: 10px 1px; }

.c-about, .c-roadmap { background-position-y: bottom; }

.c-about, .c-roadmap {
    background-image: linear-gradient(to right, transparent 50%, white 50%);
    background-position-y: bottom;
}

.c-how-to-buy, .c-call-to-action {
    background-color: white;
    background-position-y: top, bottom;
}

.c-how-to-buy__side-bar, .c-call-to-action__side-bar { color: black; }

.c-how-to-buy {
    background-image:
        linear-gradient(to right, transparent 50%, hsl(161 100% 20%) 50%),
        linear-gradient(to right, transparent 50%, hsl(353 85% 44%) 50%);
}

.c-tokenomics, .c-roadmap { background-color: hsl(353 85% 39%); }

.c-tokenomics {
    background-position-y: top;
    background-image: linear-gradient(to right, transparent 50%, white 50%);
}

.c-tokenomics__distribution-chart:before, .c-tokenomics__distribution-chart:after {
    position: absolute;
    content: "";
}

.c-tokenomics__distribution-chart:before {
    left: 50%;
    top: 0;
    width: 100vw;
    height: 100%;
    background: hsl(353 85% 39%);
    padding-top: 26px;
    box-shadow: 0 25px 15px hsl(353 85% 39%);
    margin-top: -26px;
    transform: translateX(-50%);
    z-index: -1;
}

.c-tokenomics__distribution-chart:after {
    left: 50%;
    top: 50%;
    width: 90%;
    max-width: 480px;
    height: 90%;
    max-height: 480px;
    background-image: url(../assets/img/coin-240x240.png);
    background-image: url(../assets/img/coin-240x240.webp);
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.c-call-to-action {
    background-image:
        linear-gradient(to right, transparent 50%, hsl(353 85% 44%) 50%),
        linear-gradient(to right, transparent 50%, hsl(161 100% 20%) 50%);
}

.c-footer {
    background-image:
        linear-gradient(to right, transparent 50%, white 50%),
        linear-gradient(to right, transparent 50%, hsl(353 85% 44%) 50%),
        linear-gradient(to right, hsl(353 85% 44%) 50%, transparent 50%),
        linear-gradient(to right, hsl(353 85% 44%), hsl(353 85% 44%));

    background-position-y: top, calc(100% - 5px), calc(100% - 4px), bottom;
    background-size: 10px 1px, 10px 1px, 10px 1px, 10px 4px;
    background-repeat: repeat-x;
}

@media only screen and (min-device-pixel-ratio: 1.5) {
    .c-hero__character-background {
        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-480x360@2x.png),
            url(../assets/img/2x/landscape-480x360@2x.jpg);

        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-480x360@2x.webp),
            url(../assets/img/2x/landscape-480x360@2x.webp);
    }

    @supports (display: grid) {
        .c-hero__character-background { background-image: none; }

        .c-hero__character-background:before {
            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-480x360@2x.webp"),
                url("../assets/img/2x/landscape-480x360@2x.webp");

            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-480x360@2x.png"),
                url("../assets/img/2x/landscape-480x360@2x.jpg");
        }
    }

    .c-tokenomics__distribution-chart:after {
        background-image: url(../assets/img/coin-240x240.png);
        background-image: url(../assets/img/coin-240x240.webp);
    }
}

@media only screen and (min-width: 360px) {
    .c-hero__character-background {
        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-640x480@2x.png),
            url(../assets/img/2x/landscape-640x480@2x.jpg);

        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-640x480@2x.webp),
            url(../assets/img/2x/landscape-640x480@2x.webp);
    }

    @supports (display: grid) {
        .c-hero__character-background { background-image: none; }

        .c-hero__character-background:before {
            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/clouds-640x480.webp"),
                url("../assets/img/landscape-640x480.webp");

            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/clouds-640x480.png"),
                url("../assets/img/landscape-640x480.jpg");
        }
    }

    .c-character__speech { top: -10%; }

    .c-tokenomics__distribution-chart:after {
        background-image: url(../assets/img/coin-360x360.png);
        background-image: url(../assets/img/coin-360x360.webp);
    }
}


@media only screen and (min-width: 360px) and (min-device-pixel-ratio: 1.5) {
    .c-hero__character-background {
        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-640x480@2x.png),
            url(../assets/img/2x/landscape-640x480@2x.jpg);

        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-640x480@2x.webp),
            url(../assets/img/2x/landscape-640x480@2x.webp);
    }

    @supports (display: grid) {
        .c-hero__character-background { background-image: none; }

        .c-hero__character-background:before {
            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-640x480@2x.webp"),
                url("../assets/img/2x/landscape-640x480@2x.webp");

            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-640x480@2x.png"),
                url("../assets/img/2x/landscape-640x480@2x.jpg");
        }
    }

    .c-tokenomics__distribution-chart:after {
        background-image: url(../assets/img/2x/coin-360x360@2x.png);
        background-image: url(../assets/img/2x/coin-360x360@2x.webp);
    }
}

@media only screen and (min-width: 480px) {
    body { font-size: 12pt; }

    h3 { font-size: 20pt; }

    .c-hero__character-background {
        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-960x720.png),
            url(../assets/img/landscape-960x720.jpg);

        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-960x720.webp),
            url(../assets/img/landscape-960x720.webp);
    }

    @supports (display: grid) {
        .c-hero__character-background { background-image: none; }

        .c-hero__character-background:before {
            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/clouds-960x720.webp"),
                url("../assets/img/landscape-960x720.webp");

            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/clouds-960x720.png"),
                url("../assets/img/landscape-960x720.jpg");
        }
    }

    .c-character__speech, .c-hero__summary { font-size: 15pt;  }

    .c-character__speech {
        width: 125px;
        height: 70px;
        padding: 12px 15px;
    }

    .c-tokenomics__distribution-chart:after {
        background-image: url(../assets/img/coin-480x480.png);
        background-image: url(../assets/img/coin-480x480.webp);
    }
}

@media only screen and (min-width: 480px) and (min-device-pixel-ratio: 1.5) {
    .c-hero__character-background {
        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-960x720@2x.png),
            url(../assets/img/2x/landscape-960x720@2x.jpg);

        background-image:
            linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-960x720@2x.webp),
            url(../assets/img/2x/landscape-960x720@2x.webp);
    }

    @supports (display: grid) {
        .c-hero__character-background { background-image: none; }

        .c-hero__character-background:before {
            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-960x720@2x.webp"),
                url("../assets/img/2x/landscape-960x720@2x.webp");

            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-960x720@2x.png"),
                url("../assets/img/2x/landscape-960x720@2x.jpg");
        }
    }

    .c-tokenomics__distribution-chart:after {
        background-image: url(../assets/img/2x/coin-480x480@2x.png);
        background-image: url(../assets/img/2x/coin-480x480@2x.webp);
    }
}


@media only screen and (min-width: 800px) {
    .c-tokenomics__distribution-chart:before { display: none; }

    @supports (display: grid) {
        .c-hero__character-background { background-image: none; }

        .c-hero__character-background:before {
            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/clouds-1024x768.webp"),
                url("../assets/img/landscape-1024x768.webp");

            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/clouds-1024x768.png"),
                url("../assets/img/landscape-1024x768.jpg");
        }
    }

    @supports (filter: drop-shadow(-10px 0 0 white)) {
        .c-character__media:before {
            padding-bottom: 10px;
            filter: drop-shadow(0 10px 0 white);
            mask-image: linear-gradient(-9deg, #fff 32%, #0000 34%);
        }
    }
}

@media only screen and (min-width: 800px) and (min-device-pixel-ratio: 1.5) {
    @supports (display: grid) {
        .c-hero__character-background { background-image: none; }

        .c-hero__character-background:before {
            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-1024x768@2x.webp"),
                url("../assets/img/2x/landscape-1024x7682x.webp");

            background-image:
                linear-gradient(to bottom, transparent 50%, hsl(161 100% 20%)),
                url("../assets/img/2x/clouds-1024x768@2x.png"),
                url("../assets/img/2x/landscape-1024x768@2x.jpg");
        }
    }
}

@media only screen and (min-width: 1024px) {
    h3 { font-size: 24pt; }

    .c-hero:before {
        position: absolute;
        top: 0;
        right: 0;
        width: 45%;
        height: 100%;

        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-720x1080.png),
            url(../assets/img/landscape-720x1080.jpg);

        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-720x1080.webp),
            url(../assets/img/landscape-720x1080.webp);

        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        z-index: -1;
    }

    .c-hero__character-background, .c-hero__character-background:before { background: none; }

    @supports (filter: drop-shadow(-10px 0 0 white)) {
        .c-character__media:before {
            left: -10px;
            background-position-x: 10px;
            padding-left: 10px;
            padding-bottom: 0px;
            filter: drop-shadow(-10px 0 0 white);
            mask-image: linear-gradient(75deg, white 49%, transparent 43%);
            animation: add-shade 3s cubic-bezier(0.16, 1, 0.3, 1);
        }
    }

    .c-character__speech { top: -5%; }
}

@media only screen and (min-width: 1024px) and (min-device-pixel-ratio: 1.5) {
    .c-hero:before {
        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-720x1080@2x.png),
            url(../assets/img/2x/landscape-720x1080@2x.jpg);

        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-720x1080@2x.webp),
            url(../assets/img/2x/landscape-720x1080@2x.webp);
    }
}

@media only screen and (min-width: 1440px) {
    .c-hero:before {
        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-960x1080.png),
            url(../assets/img/landscape-960x1080.jpg);

        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-960x1080.webp),
            url(../assets/img/landscape-960x1080.webp);
    }
}

@media only screen and (min-width: 1440px) and (min-device-pixel-ratio: 1.5) {
    .c-hero:before {
        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-960x1080@2x.png),
            url(../assets/img/2x/landscape-960x1080@2x.jpg);

        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-960x1080@2x.webp),
            url(../assets/img/2x/landscape-960x1080@2x.webp);
    }
}

@media only screen and (min-width: 1920px) {
    @supports (mask: linear-gradient(to bottom, black, black)) {
        .c-header:before, .c-header:after { display: none; }

        .c-header {
            background-image:
                linear-gradient(to right, hsl(353 85% 44%), hsl(353 85% 44%)),
                linear-gradient(to right, hsl(353 85% 44%) 50%, transparent 50%),
                linear-gradient(to right, transparent 50%, hsl(353 85% 44%) 50%);
        }
    }
}

@media only screen and (min-width: 1921px) {
    .c-hero:before {
        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-1440x1080.png),
            url(../assets/img/landscape-1440x1080.jpg);

        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/clouds-1440x1080.webp),
            url(../assets/img/landscape-1440x1080.webp);
    }
}

@media only screen and (min-width: 1921px) and (min-device-pixel-ratio: 1.5) {
    .c-hero:before {
        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-1440x1080@2x.png),
            url(../assets/img/2x/landscape-1440x1080@2x.jpg);

        background-image:
            radial-gradient(ellipse farthest-side at top right, transparent 50%, hsl(161 100% 20%)),
            url(../assets/img/2x/clouds-1440x1080@2x.webp),
            url(../assets/img/2x/landscape-1440x1080@2x.webp);
    }
}

/* @keyframes add-shade {
    from { filter: drop-shadow(0 0 0 currentColor); }
}

@keyframes fly-in { /* TODO: extract cubic-bezier(0.16, 1, 0.3, 1) values to enable hover
    from { transform: translate(calc(50vw + 50%), 0) scale(0); }
    to { transform: translateX(0) scale(100%); }
} */

@keyframes hover {
    0% { transform: translateY(0); }
    50% { transform: translateY(5%); }
    100% { transform: translateY(0); }
}

.c-input, .c-input.-is-small {
    font-size: 11pt;
    border-radius: 3px;
}

.c-input {
    padding: 6px 10px;
    border: none;
}

.c-input.-is-multi-line { height: 10em; }

.c-icon-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.c-icon-list__item { display: inline-block; }

.c-icon-list__item + .c-icon-list__item { margin-left: 20px; }

@supports (flex-wrap: wrap) {
    .c-icon-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .c-icon-list__item { margin-left: 0 !important; }
}

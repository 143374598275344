.c-brand-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    align-items: center;
    gap: 25px;
    padding: 0;
    margin: 0;
}

.c-brand-list__item {
    max-width: 150px;
    flex: 1 1 33.333%;
}

.c-brand-list__logo {
    display: block;
    width: 100%;
}

@media only screen and (min-width: 1024px) {
    .c-brand-list { gap: 50px; }
}

/* based on: https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */

.u-visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    /* 1 */
}

body { overflow-x: hidden; /* HACK */ }

h2, .c-hero__summary { font-size: 12pt; }

h3, .c-header__logo, .c-side-bar__logo { font-size: 15pt; }

h3 { line-height: 1.2; }

h2 + h3 { margin-top: 50px; }

h2 + p,
h3 + p,
h2 + dl,
h3 + dl,
h2 + ul,
h3 + ul,
h2 + ol,
h3 + ol,
.c-hero__summary
.c-footer__socials,
.c-footer__copyright,
.c-contact-form,
.c-contact-form__row + .c-contact-form__row,
.c-contact-form__input-set + .c-contact-form__input-set,
.c-contact-form__submit-button,
.c-footer__disclaimer,
.c-footer__socials {
    margin-top: 25px;
}

p + p { margin-top: 1em; }

ul { padding-left: 1.1em; }

.c-header {
    position: fixed;
    width: 100%;
    z-index: 2;
}

.c-header__inner, .c-hero__inner, .c-footer__inner {
    width: 100%;
    box-sizing: border-box;
    padding: 0 25px;
}

.c-header__inner {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.c-header__nav-button {
    padding: 14px 7px;
    user-select: none;
}

.c-header__nav-icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 2px;
    color: transparent;
    transition: background-color .25s;
}

.c-header__nav-icon:before, .c-header__nav-icon:after {
    position: absolute;
    display: block;
    content: "";
    width: 16px;
    height: 2px;
}

.c-header__nav-icon:before { margin-top: -5px; }

.c-header__nav-icon:after { top: 5px; }

.c-header__nav-button:has(+ .c-header__nav-icon-checkbox:checked) .c-header__nav-icon { background-color: transparent; }

.c-header__nav-icon:before, .c-header__nav-icon:after { transition: transform .5s; }

.c-header__nav-button:has(+ .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:before,
.c-header__nav-button:has(+ .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:after {
    transform-origin: center;
}

.c-header__nav-button:has(+ .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:before {
    transform: translateY(250%) rotate(45deg);
}

.c-header__nav-button:has(+ .c-header__nav-icon-checkbox:checked) .c-header__nav-icon:after {
    transform: translateY(-250%) rotate(-45deg);
}

.c-header__nav-icon-checkbox, .c-side-bar, .c-how-to-buy__side-bar, .c-call-to-action__side-bar { display: none; }

.c-header__nav {
    max-height: 0;
    margin-top: 11px;
    margin-bottom: 2.5px;
    overflow: hidden;
}

.c-header__nav-icon-checkbox:checked + .c-header__nav {
    max-height: 100%;
    overflow: initial;
}

@supports (flex-wrap: wrap) {
    .c-header__inner {
        flex-wrap: wrap;
        padding: 22.5px 25px;
    }

    .c-header__logo { flex: 1 1 75%; }
}

@supports (display: grid) {
    .c-header__inner {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 75px 0fr;
        padding: 0 25px;
        transition: grid-template-rows .5s;
    }

    .c-header__inner:has(.c-header__nav-icon-checkbox:checked) { grid-template-rows: 75px 1fr; } 

    .c-header__logo { flex: 1 1 75%; }

    .c-header__nav {
        grid-column: 1 / span 2;
        max-height: 100%;
        padding: 0;
        margin: 0;
        transform: scaleY(0);
        transition: scaleY .5s;
    }

    .c-header__nav-icon-checkbox:checked + .c-header__nav {
        overflow: hidden;
        transform: scaleY(1);
    }

    .c-header__nav-items { margin-bottom: 25px; }
}

.c-hero, .c-footer { padding-bottom: 25px; }

.c-hero { position: relative; }

.c-hero__inner, .c-call-to-action__call, .c-call-to-action__action-set { text-align: center; }

.c-hero__title { display: none; }

.c-hero__character-background {
    position: relative;
    width: 100vw;
    padding: 100px 25px 0 25px;
    margin-left: -25px;
    box-sizing: border-box;
}

.c-hero__character-background:before {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100px + 55%);
    content: "";
}

.c-character, .c-character__media { position: relative; }

.c-character__media {
    display: inline-block;
    transform: rotate(15deg);
}

.c-hero__character, .c-hero__summary { margin-top: 75px; }

.c-hero__character {
    max-width: 500px;
    margin: 0 auto;
    animation:
        fly-in 3s cubic-bezier(0.16, 1, 0.3, 1),
        hover 10s infinite ease-in-out 3s;
}

.c-hero__summary {
    display: inline-block;
    max-width: 50ch;
}

@supports (display: grid) {
    .c-hero__character-background {
        display: grid;
        grid-template-columns: 25px 1fr 25px;
        grid-template-rows: 100px 9fr 3fr 1fr;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .c-hero__character-background:before {
        display: block;
        height: 100%;
        grid-column: 1 / span 3;
        grid-row: 1 / span 2;
        content: "";
    }

    .c-hero__character {
        grid-column: 2;
        grid-row: 2 / span 2;
        align-self: end;
    }

    @supports (filter: drop-shadow(-10px 0 0 white)) {
        .c-hero__summary { margin-top: 35px; }
    }
}

.c-footer { padding-top: 25px; }

.c-contact-form {
    text-align: right;
    margin-bottom: 0;
}

.c-contact-form__input-set + .c-contact-form__input-set { display: block; }

.c-contact-form__input { width: 100%; }

.c-footer__aside { margin-top: 50px; }

.c-footer__sitemap { @mixin visually-hidden; }

@media only screen and (min-width: 360px) {
    .c-contact-form__row:first-child {
        display: flex;
        gap: 25px;
    }

    .c-contact-form__input-set { flex: 1; }

    .c-contact-form__input-set + .c-contact-form__input-set { margin-top: 0; }
}

@media only screen and (min-width: 480px) {
    @supports (display: grid) {
        .c-hero__character-background {
            grid-template-rows: 25vw 9fr 3fr 1fr;
        }
    }
}

@media only screen and (min-width: 640px) {
    @supports (display: grid) { 
        .c-hero__title {
            display: inline-block;
            position: absolute;
            left: calc(50% - 200px);
            max-width: 400px;
            margin-top: 100px;
            margin-bottom: 0;
            filter: drop-shadow(0 0 10px black);
            z-index: 1;
        }

        .c-hero__character-background { grid-template-rows: 225px 9fr 3fr 1fr; }
    }

    .c-footer__inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto) 1fr;
        gap: 25px;
    }

    .c-contact-form__row:first-child { display: initial; }

    .c-contact-form__input-set + .c-contact-form__input-set { margin-top: 25px; }

    .c-footer__aside, .c-footer__socials, .c-footer__copyright { margin-top: 0; }

    .c-footer__sitemap { padding-top: .3em; }

    .c-footer__socials, .c-footer__copyright { grid-column: 1 / span 2; }
}

@media only screen and (min-width: 800px) {
    .c-header { height: 100px; }

    .c-header__inner {
        display: flex;
        padding-right: 0;
    }

    .c-header__logo {
        flex: none;
        margin-top: -.2em;
    }

    .c-header__nav-button, .c-header__buy-button { display: none; }

    .c-header__nav {
        padding-bottom: 0;
        overflow: initial;
        transform: scaleY(1);
    }

    .c-header__nav-items { margin-bottom: 0; }

    .c-hero, .c-footer { padding-bottom: 50px; }

    .c-hero__title { display: inline-block; }

    .c-hero__summary, .c-contact-form, .c-footer__disclaimer { margin-top: 50px; }

    @supports (display: grid) { 
        .c-hero__title { margin-top: 125px; }

        .c-hero__character-background { grid-template-rows: 275px 9fr 3fr 1fr; }

        .c-hero__summary { margin-top: 70px; }
    }

    .c-footer { padding-top: 50px; }

    .c-footer__inner { gap: 50px; }

    .c-footer__contact { grid-row: 1 / span 3; }

    .c-contact-form__input-set + .c-contact-form__input-set,
    .c-footer__socials,
    .c-footer__copyright {
        margin-top: 0;
    }

    .c-contact-form__row:first-child { display: flex; }

    .c-footer__socials, .c-footer__copyright {
        grid-column: 2;
        justify-self: right;
    }
}

@media only screen and (min-width: 1024px) {
    .c-header__inner { padding-right: 25px; }

    .c-header__buy-button { display: inline-block; }

    .c-hero {
        padding-top: 150px;
        padding-bottom: 200px; 
    }

    .c-hero__inner {
        display: grid;
        text-align: initial;
        grid-template-columns: 4fr .769fr .979fr 5.253fr 1fr;
        grid-template-rows: 1fr repeat(2, auto);
        row-gap: 50px;
    }

    .c-hero__title, .c-hero__summary { margin: 0; }

    .c-hero__title {
        position: initial;
        grid-column: 1 / span 2;
        grid-row: 1;
        align-self: end;
        padding-right: 50px;
        filter: none;
    }

    .c-hero__character-background {
        display: block;
        grid-column: 2 / span 3;
        grid-row: 1 / span 3;
        align-self: center;
        width: auto;
        background: none;
        padding: 0;
        margin: 0;
    }

    .c-hero__character { max-width: none; }

    .c-hero__summary { grid-column: 1; }

    .c-footer__inner { grid-template-columns: 2fr 3fr; }

    .c-footer__aside {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        gap: 50px;
    }

    .c-footer__disclaimer {
        grid-column: 2;
        grid-row: 2;
        margin-top: .2em;
    }

    .c-footer__sitemap {
        position: initial;
        width: initial;
        height: initial;
        grid-column: 1;
        clip: initial;
        margin: 0;
    }
}

@media only screen and (min-width: 1280px) {
    .c-header__inner, .c-hero__inner, .c-footer__inner {
        width: 1180px;
        padding-left: 50px;
        padding-right: 50px;
        margin: 0 auto;
    }

    .c-hero { padding-bottom: 400px; }
}

@media only screen and (min-width: 1920px) {
    @supports (mask: linear-gradient(to bottom, black, black)) {
        .c-header { height: 7px; }

        .c-header__inner { display: none; }

        .c-side-bar {
            display: block;
            position: fixed;
            left: 50px;
            top: 50px;
            z-index: 1;
        }
    }
}

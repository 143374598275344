.c-button, .c-button:after, .c-button.-is-small, .c-button.-is-small:after { border-radius: 3px; }

.c-button {
    position: relative;
    display: inline-block;
    background: hsl(353 85% 44%);
    font-weight: bold;
    text-decoration: none;
    color: white;
    line-height: normal;
    border: none;
    box-shadow: 0 4px 4px -2px hsl(0 0% 0% / .25);
    transition: box-shadow .1s, transform .1s;
    cursor: pointer;
}

.c-button, .c-button.-is-small {
    font-size: 11pt;
    padding: 6px 10px;
}

.c-button:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    transition: filter .5s, opacity .5s;
}

.c-button:after, .c-button.-is-sunny-on-top:after {
    background-image: radial-gradient(ellipse farthest-side at top right, hsl(55 92% 71% / .2), transparent);
}

.c-button.-is-large, .c-button.-is-large:after { border-radius: 5px; }

.c-button.-is-large {
    font-size: 15pt;
    padding: 8px 15px;
}

.c-button.-is-sunny-on-bottom:after {
    background-image: radial-gradient(ellipse farthest-side at bottom left, hsl(55 92% 71% / .2), transparent);
}

.c-button:hover:after, .c-button:focus:after { @mixin opacity 0; }

@supports (filter: opacity()) {
    .c-button:hover:after, .c-button:focus:after {
        opacity: initial;
        filter: opacity(0);
    }
}

.c-button:active {
    transform: translateY(2px);
    box-shadow: none;
}
@media only screen and (min-width: 800px) {
    .c-button.-is-large { font-size: 18pt; }
}
